import React from "react";
import { configuration } from "../../config/config";
import { ISRTL } from "../transtext/transtext";

export const Fetch_API = async (url) => {
    let s_id = window.localStorage.getItem('s_id')
    let headers = { Authorization: configuration.Authentication , s_id: s_id ? s_id : ''  };
    const res = await fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/${url}?language=${ISRTL ? 'ar' : 'en'}`,{headers})
    const data = await res.json()
   // console.log(data)
    return data
}

export const Sign_in = (data) => {
    let headers = { Authorization: configuration.Authentication ,  Accept: 'application/json', 'Content-Type': 'application/json', s_id : window.localStorage.getItem('s_id') };
    const send = fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/sign_in`,{ method: 'POST' , headers , body : JSON.stringify(data)})
    .then( async(res) => {
        //console.log(res)
        window.localStorage.setItem('s_id' , res.headers.get('s_id'))
        return await res.json()
    })
    return send
}


export const signOut = async () => {
    let s_id = window.localStorage.getItem('s_id')
    let headers = { Authorization: configuration.Authentication , s_id: s_id ? s_id : ''  };
    const res = await fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/sign_out`, {
      method: 'POST',
      headers,
    }).then(async (res) => {
      const data = await res.json();
      window.localStorage.setItem('login', false)
      return data;
    });
    window.location.reload();
    return res;

  };

  export const customer_appointment = async (data) =>{
    let s_id = window.localStorage.getItem('s_id')
    let headers = { Authorization: configuration.Authentication , s_id: s_id ? s_id : '' , Accept: 'application/json', 'Content-Type': 'application/json' };
    const res = await fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/customer_appointment`,{ method: 'POST' , headers , body : JSON.stringify(data)})
    .then( async(res) => {
    console.log(res)
    return await res.json()
    })
    return res
  }

  export const Delete_appointment = async (ID) => {
    let s_id = window.localStorage.getItem('s_id')
    let headers = { Authorization: configuration.Authentication , s_id: s_id ? s_id : '' };
    const res = await fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/customer_appointment/${ID}`,{method : 'DELETE' , headers}).then( async(res) => {
      return await res.json()
    })
    window.location.reload();
    return res
  }

  export const Update_Account = async (data) =>{
    let s_id = window.localStorage.getItem('s_id')
    let headers = { Authorization: configuration.Authentication , s_id: s_id ? s_id : '' , Accept: 'application/json', 'Content-Type': 'application/json' };
    const res = await fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/account`,{ method: 'PUT' , headers , body : JSON.stringify(data)})
    .then( async(res) => {
      return await res.json()
    })
    window.location.reload()
  return res
  
  }


  export const Sign_up = (data) => {
    let headers = { Authorization: configuration.Authentication ,  Accept: 'application/json', 'Content-Type': 'application/json', s_id : window.localStorage.getItem('s_id') };
    const send = fetch(`https://api.1daycloud.com/v3/appointment/${configuration.ID}/sign_up`,{ method: 'POST' , headers , body : JSON.stringify(data)})
    .then( async(res) => {
        window.localStorage.setItem('s_id' , res.headers.get('s_id'))
        return await res.json()
    })
    return send 
  }