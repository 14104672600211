import React, { Fragment } from "react";
import Navhelper from "../../helper/NavHelper";
import Mobilemenu from "./Mobilemenu";
import { Link } from "react-router-dom";
// import navigation from "../../data/navigation.json";
import TransText, { ISRTL } from "../transtext/transtext";
import Button_language from "../button_language/button_language";
import styled from "styled-components";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import UserAvatar from "../UserLogin/UserLogin";

export const Header_Wrapper = styled.div`
  @media (max-width: 1300px) {
    display: flex;
    justify-content: center;
  }
`;

export const Div_GAP = styled.div`
  gap: 100px;
  @media (max-width: 415px) {
    gap: 30px;
  }
`;

class Headertwo extends Navhelper {
  //   componentDidMount() {
  //     console.log(ISRTL);
  //   }
  constructor(props) {
    super(props);
    this.state = {
      islogin: false,
    };
  }
  componentDidMount() {
    this.setState({ islogin: window.localStorage.getItem("login") });
    //console.log(this.state.islogin);
  }
  render() {
    return (
      <Fragment>
        {/* Mobile Menu */}
        <aside
          className={
            this.state.navMethod === true
              ? "sigma_aside aside-open"
              : "sigma_aside"
          }
        >
          <div className="sigma_close aside-trigger" onClick={this.toggleNav}>
            <span />
            <span />
          </div>
          <Mobilemenu />
        </aside>
        <div
          className="sigma_aside-overlay aside-trigger"
          onClick={this.toggleNav}
        />
        {/* Mobile Menu */}
        {/* Header */}
        <header
          style={{
            backgroundColor: `${this.props.isprofile ? "#1B1564" : ""}`,
          }}
          className={"sigma_header style-5 shadow-none can-sticky"}
        >
          <div className="container">
            <div className="sigma_header-top d-none d-md-block">
              <div className="sigma_header-top-inner">
                {/* <div className="sigma_header-top-links">
                  <ul className="sigma_header-top-nav">
                    <li>
                      <Link to="#">
                        <i className="fal fa-envelope" />
                        example@example.com
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fal fa-map-marker-alt" />
                        Oakwood, Los Angeles, CA 1098
                      </Link>
                    </li>
                  </ul>
                </div> */}

                <div className="sigma_header-top-contacts">
                  <ul
                    style={{
                      direction: `${ISRTL ? "ltr" : ""}`,
                    }}
                    className="sigma_header-top-nav"
                  >
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/1daycloud"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/onedaycloud" target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/onedaycloud"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                  {this.state.islogin == "true" ? <UserAvatar /> : null}
                  <Button_language />
                </div>
              </div>
            </div>
          </div>

          {/* strat header */}
          <div className="container">
            <Header_Wrapper
              style={{ borderRadius: "7px" }}
              className="sigma_header-middle pl-4 pr-4"
            >
              <Div_GAP
                className="navbar"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="sigma_logo-wrapper">
                  {/* Logo here */}
                  <Link to="/" className="sigma_logo">
                    <img
                      src={
                        process.env.PUBLIC_URL + `/assets/img/logo-white.png`
                      }
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <ul
                    style={{ gap: `${ISRTL ? "15px" : ""}` }}
                    className="navbar-nav"
                  >
                    {/* Data */}
                    {/* {navigation.map((item, i) => (
                      <li
                        key={i}
                        className={
                          item.child === true
                            ? "menu-item menu-item-has-children"
                            : "menu-item"
                        }
                      >
                        {item.child === true ? (
                          <Link to="#">{item.linkText}</Link>
                        ) : (
                          <Link to={item.link}>{item.linkText}</Link>
                        )}
                        {item.child === true ? (
                          <ul className="sub-menu">
                            {item.submenu.map((item, i) => (
                              <li
                                key={i}
                                className={
                                  item.child === true
                                    ? "menu-item menu-item-has-children"
                                    : "menu-item"
                                }
                              >
                                {item.child === true ? (
                                  <Link to="#">{item.linkText}</Link>
                                ) : (
                                  <Link to={item.link}>{item.linkText}</Link>
                                )}
                                {item.child === true ? (
                                  <ul className="sub-menu">
                                    {item.submenu.map((item, i) => (
                                      <li className="menu-item" key={i}>
                                        <Link to={item.link}>
                                          {item.linkText}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))} */}

                    {/* my nav */}
                    {/* my nav section */}
                    <li key={1} className={"menu-item menu-item-has-children"}>
                      <Link style={{ color: "#BAB7D4" }} to="/">
                        <TransText id="Branch" default_message="Branch" />
                      </Link>
                      {/* <ul className="sub-menu">
                        <li
                          key={2}
                          className={"menu-item menu-item-has-children"}
                        >
                          <Link to={"/"}>
                            <TransText id="Home" default_message="Home" />
                          </Link>
                        </li>
                      </ul> */}
                    </li>

                    <li key={3} className={"menu-item menu-item-has-children"}>
                      <Link style={{ color: "#BAB7D4" }} to="/all-item">
                        <TransText id="Item" default_message="Item" />
                      </Link>
                      {/* <ul className="sub-menu">
                        <li
                          key={4}
                          className={"menu-item menu-item-has-children"}
                        >
                          <Link to={"/all-item"}>
                            <TransText
                              id="All_Item"
                              default_message="All Item"
                            />
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    {/* my nav section */}

                    {/* my nav */}
                    {/* Data */}
                  </ul>
                  <div
                    style={{
                      marginRight: `${ISRTL ? "15px" : ""}`,
                    }}
                    className="sigma_header-controls style-2"
                  >
                    <ul
                      style={{ gap: `${ISRTL ? "20px" : ""}` }}
                      className="sigma_header-controls-inner"
                    >
                      {/* <li
                        style={{
                          marginLeft: `${ISRTL ? "0px" : "20px"}`,
                        }}
                        className="d-none d-sm-block "
                      >
                        <Link to="/doctor-grid" className="sigma_btn btn-sm">
                          <TransText
                            id="Find A Item"
                            default_message="Find A Item"
                          />

                          <i className="fal fa-plus ml-3" />
                        </Link>
                      </li> */}
                      <li>
                        <div>
                          <Button_language ishere />
                        </div>
                      </li>

                      <li
                        className="aside-toggle aside-trigger"
                        onClick={this.toggleNav}
                      >
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                </div>
              </Div_GAP>
            </Header_Wrapper>
          </div>

          {/* Header */}
        </header>

        {/* Header */}
      </Fragment>
    );
  }
}

export default Headertwo;
