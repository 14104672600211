import React, {useEffect , useState} from "react";
import { Fetch_API ,Delete_appointment } from "../../fetch/fetch";
import TransText ,{ISRTL} from "../../transtext/transtext";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Appointments_Content = () => {
const [data , setData] = useState(null)
const [total , setTotal] = useState(0)

const fetch = async () => {
    const res = await Fetch_API('customer_appointment')
    //console.log(res)
    return res 
}


    useEffect(async () => {
        const res = await fetch()
        setData(res.data)
       
    },[])

    return(
        <div className="sidebar-style-9">
        <div className="section section-padding">
          <div style={{ justifyContent: "center" }} className="container">
            <div style={{ justifyContent: "center" }} className="row">
            <div className="col-lg-8">
            <div className="row">
                {data ? data.length == 0 ? <h1><TransText id='no data to show' default_message="no data to show" /></h1> :  data.map((appointment , index) => {
                    let num = 0
                    return(
                        <div className="col-lg-6 col-md-6" key={index}>
                        <div className="sigma_team style-16">
                          <div className="sigma_team-thumb">
                              <img src={appointment.employee.photo.file_url ? appointment.employee.photo.file_url : `${process.env.PUBLIC_URL}/assets/img/no-image.png`  } />
                          </div>
                          <div style={{textAlign: `${ISRTL ? 'right' : ''}`}} className="sigma_team-body">
                          <span>
                                {/* <TransText id="Item_name" default_message="Item_name" /> */}
                                </span>
                                <h5 style={{display:'inline'}}>{' '}
                                {appointment.employee.name}
                                </h5>
                                <br />
                                <span>
                                {/* <TransText id="Date" default_message="Date" />{' : '} */}
                                </span>
                                <span style={{display:'inline'}}>
                                {appointment.date}
                                </span>
                                <br />
                                <span>
                                {/* <TransText id="Time" default_message="Time" />{' : '} */}
                                </span>
                                <span style={{display:'inline'}}>
                                {appointment.time}
                                </span>
                                <br />
                                <br />
                                <h5 >
                                <TransText id="services" default_message="services" />
                                </h5>
                               
                                {appointment.services ? appointment.services.map((services , index) => {
                                    return(
                                        <div style={{display:'flex', justifyContent:'space-between'}}  key={index}>
                                            <span>
                                            {services.service.name}
                                            </span>
                                            <span style={{display:'inline' , direction:`${ISRTL ? 'rtl' :''}`}}>
                                            {parseFloat(services.amount)}{' '}
                                            <TransText id='SAR' default_message='SAR' />
                                            </span>
                                        </div>
                                    )
                                }) : null}
                                <br />
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                <h5>
                                <TransText id="Total" default_message="Total" />
                                </h5>
                                <h5 style={{display:'inline'}} >
                                    
                                {appointment.services ? appointment.services.map((services , index) => {
                                     num += parseFloat(services.amount)
                                }) : null}
                                {num} {' '}
                                <TransText id='SAR' default_message='SAR' />
                                </h5>
                                </div>
                                <div className="sigma_team-info">
                          
                          <Link  className="sigma_btn btn-block btn-sm" onClick={() => Delete_appointment(appointment.id)}>
                                <TransText id="delete appointment" default_message="delete appointment" />
                         </Link>
                          </div>
                          </div>
                          </div>
                          </div>
                    )
                })
                
                : null }

                             </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Appointments_Content;