import React from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Headertwo";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import Appointments_Content from "../sections/Appointments_Content/appointments_Content";

const Appointments = () => {
  return (
    <>
      <Header />
      <Breadcrumbs />
      <Appointments_Content />
      <Footer />
    </>
  );
};

export default Appointments;
