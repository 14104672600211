import React, { Component } from "react";
import { Link } from "react-router-dom";
import serviceblock from "../../data/service/service.json";
import TransText, { ISRTL } from "../transtext/transtext";

class Footer extends Component {
  render() {
    return (
      <footer className="sigma_footer style-5 pb-0">
        <div className="container">
          {/* <div className="sigma_info-wrapper style-26 mb-5">
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-map-marker-alt" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Our Address</p>
                                <p className="secondary-color"><b>Drive Chicago, IL 60607</b>
                                </p>
                            </div>
                        </div>
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-phone" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Call Us</p>
                                <p className="secondary-color"><b>360-779-2228</b>
                                </p>
                            </div>
                        </div>
                        <div className="sigma_info style-26">
                            <div className="sigma_info-title">
                                <span className="sigma_info-icon">
                                    <i className="fal fa-envelope" />
                                </span>
                            </div>
                            <div className="sigma_info-description">
                                <p>Our Mail</p>
                                <p className="secondary-color"><b>example@example.com</b>
                                </p>
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="sigma_footer-middle">
          <div className="container">
            <div style={{ justifyContent: "space-around" }} className="row">
              <div className="col-lg-4">
                <div className="sigma_footer-widget">
                  <div className="sigma_footer-logo mb-4">
                    <Link to={"/"}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/logo-white.png"
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="row">
                    <div className="col-sm-9">
                      <p
                        style={{ fontFamily: `${ISRTL ? "arabicFont" : ""}` }}
                        className="mb-0"
                      >
                        <TransText
                          id="Footer_Text"
                          default_message="Footer_Text"
                        />
                      </p>
                    </div>
                  </div>
                  <ul className="sigma_social-icons has-border mt-4 justify-content-start">
                    <li style={{ marginLeft: "10px" }}>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/1daycloud"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/onedaycloud" target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/oneday.cloud/"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/onedaycloud"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Data */}
              {/* <div className="col-lg-3 col-sm-3">
                <div className="sigma_footer-widget">
                  <h5 className="widget-title">Services</h5>
                  <ul className="sigma_footer-links">
                   
                    {serviceblock.slice(0, 5).map((item, i) => (
                      <li key={i}>
                        <Link to={"/service-details/" + item.id}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  
                  </ul>
                </div>
              </div> */}
              {/* Data */}
              <div className="col-lg-2 col-sm-3">
                <div className="sigma_footer-widget">
                  <h5 className="widget-title">
                    <TransText
                      id="Useful Links"
                      default_message="Useful Links"
                    />
                  </h5>
                  <ul className="sigma_footer-links">
                    <li>
                      <Link to="/">
                        {" "}
                        <TransText id="Branch" default_message="Branch" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/about">About Us</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/blog">News</Link>
                    </li> */}
                    <li>
                      <Link to="/all-item">
                        <TransText id="Item" default_message="Item" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/contact">Contact Us</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6">
                <div className="sigma_footer-widget">
                  <h5 className="widget-title">Subscribe</h5>
                  <form>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                    <button type="button" className="mt-3 btn-block">
                      Subscribe
                    </button>
                    <p className="mb-0 mt-3">
                      Get The Latest Updates via email. Any time you may
                      unsubscribe
                    </p>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div
            style={{ justifyContent: `center !important` }}
            className="sigma_footer-bottom "
          >
            <div className="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
              <p className="mb-0">
                <TransText id="Developed by" default_message="Developed by" />{" "}
                <a href="https://1daycloud.com/" target="_blank">
                  1DAY CLOUD
                </a>
              </p>
            </div>
            {/* <ul className="sigma_footer-links">
              <li>
                <Link to="#">Privacy</Link>
              </li>
              <li>
                <Link to="#">Terms</Link>
              </li>
              <li>
                <Link to="#">Sitemap</Link>
              </li>
              <li>
                <Link to="#">Help</Link>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
