import React, { Suspense, useLayoutEffect } from "react";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Appointments from "./components/pages/Appointments";
import User_profile from "./components/pages/user_profile";
import styled from "styled-components";
import MetaTags from "react-meta-tags";

let lang = window.localStorage.getItem("lang");
export const Font_Div = styled.div`
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    //color: ${lang == "ar" ? "" : "red"};
  }
`;

// Home
// const Home = React.lazy(() => import("./components/pages/Home"));
// const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// Blog
// const Blog = React.lazy(() => import("./components/pages/Blog"));
// const Blogstandard = React.lazy(() =>
//   import("./components/pages/Blogstandard")
// );
// const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// About
const About = React.lazy(() => import("./components/pages/About"));
// Services
const Services = React.lazy(() => import("./components/pages/Services"));
const Servicedetails = React.lazy(() =>
  import("./components/pages/Servicedetails")
);
// FAQ's
// const Faqs = React.lazy(() => import("./components/pages/Faqs"));
// Appointment
const Appointment = React.lazy(() => import("./components/pages/Appointment"));
// Clinics
const Clinicgrid = React.lazy(() => import("./components/pages/Clinicgrid"));
// const Cliniclist = React.lazy(() => import("./components/pages/Cliniclist"));
const Clinicdetails = React.lazy(() =>
  import("./components/pages/Clinicdetails")
);
// Doctors
const Doctorgrid = React.lazy(() => import("./components/pages/Doctorgrid"));
// const Doctorlist = React.lazy(() => import("./components/pages/Doctorlist"));
const Doctordetails = React.lazy(() =>
  import("./components/pages/Doctordetails")
);
// Contact
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Extra
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    let d = window.localStorage.getItem("lang");
    if (d === "ar") {
      document.querySelector("body").dir = "rtl";
      document.querySelector("body").style.textAlign = "right";
    } else {
      document.querySelector("body").dir = "ltr";
    }
  }, [pathname]);

  return children || null;
});
// basename={"/themes/themeforest/react/docfind/"}
function App() {
  return (
    <>
      <MetaTags>
        <meta
          name="description"
          content={
            lang == "ar"
              ? "صمم تطبيقك السحابي، عدل تصميم تطبيقك، دون شيفرات برمجية، تطبيق سحابي، ابحث عن تطبيقات الأعمال، طور عملك، منصة تصميم التطبيقات، واكب التحول الرقمي، بسط إجراءات العمل، تخلص من الورق، متجر التطبيقات، ادفع حسب الاستخدام، كن دائماً على اطلاع"
              : "Design Cloud Application, Customize Your App, Zero Code Platform, Cloud Application Platform, Grow your Business, Find Business Apps, Request Custom App, OneDay App Store, Simplify your Business, Automate Business Process, Stay up to Date, Pay As You Go, Run on the Fly"
          }
        />
        <meta
          name="keywords"
          content={lang == "ar" ? "جدولة,موعد,حجز" : "appointment,schedule"}
        />
      </MetaTags>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Font_Div className={lang == "ar" ? "ar" : "en"}>
            <Router>
              <Switch>
                <Route exact path="/" component={Clinicgrid} />
                <Route
                  exact
                  path="/branch-details/:id"
                  component={(props) => (
                    <Clinicdetails {...props} key={window.location.pathname} />
                  )}
                />

                <Route exact path="/all-item" component={Doctorgrid} />
                <Route exact path="/all-item/:id" component={Doctorgrid} />
                <Route
                  exact
                  path="/item-details/:id"
                  component={(props) => (
                    <Doctordetails {...props} key={window.location.pathname} />
                  )}
                />

                <Route exact path="/appointment" component={Appointment} />
                {/* Appointments login */}
                <Route exact path="/appointments" component={Appointments} />

                {/* User_profile login */}
                <Route exact path="/user_profile" component={User_profile} />
                {/* User_profile login */}

                {/* Extra */}
                <Route exact path="/error-page" component={Errorpage} />
                <Route exact component={Errorpage} />
              </Switch>
            </Router>
          </Font_Div>
        </ScrollToTop>
      </Suspense>
    </>
  );
}

export default App;

{
  /*     

   {/* Clinics 
             <Route
              exact
              path="/clinic/cat/:catId"
              component={(props) => (
                <Clinicgrid {...props} key={window.location.pathname} />
              )}
            /> 

               <Route exact path="/clinic-list" component={Cliniclist} /> 


             <Route exact
              path="/doctor/cat/:catId"
              component={(props) => (
                <Doctorgrid {...props} key={window.location.pathname} />
              )}
            />

              <Route
              exact
              path="/service-details/:id"
              component={(props) => (
                <Servicedetails {...props} key={window.location.pathname} />
              )}
            /> 

            <Route
              exact
              path="/service/cat/:catId"
              component={(props) => (
                <Services {...props} key={window.location.pathname} />
              )}
            /> 


              <Route
             exact
              path="/blog/cat/:catId"
              component={(props) => (
                <Blog {...props} test="test" key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/tag/:tagId"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/search/:query"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/author/:authorId"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-standard" component={Blogstandard} />
            <Route
              exact
              path="/blog-details/:id"
              component={(props) => (
                <Blogdetails {...props} key={window.location.pathname} />
              )}
            />

*/
}

{
  /* <Route exact path="/doctor-list" component={Doctorlist} /> */
}

{
  /* <Route exact path="/contact" component={Contact} /> */
}

{
  /* <Route exact path="/faqs" component={Faqs} /> */
}

{
  /* <Route exact path="/services" component={Services} /> */
}
{
  /* <Route exact path="/about" component={About} /> */
}

{
  /* Home */
}
{
  /* <Route exact path="/" component={Home} /> */
}
{
  /* <Route exact path="/home-v2" component={Hometwo} /> */
}
