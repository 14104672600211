import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  margin-top: 150px;
  @media (max-width: 415px) {
    margin-top: 0px;
  }
`;

export const Contenr_Wrapper = styled.div`
  width: 80%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  @media (max-width: 1101px) {
    padding-top: 160px;
  }
`;

export const LeftSide = styled.div`
  width: 20%;
  height: 150px;
  padding: 30px;
  border-radius: 10px;
  background-color: rgb(247, 247, 247);
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 6px;
  @media (max-width: 1300px) {
    width: 25%;
  }
  @media (max-width: 1101px) {
    position: absolute;
    width: 100%;
    //font-size: 2px;
  }
`;

export const USER_Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 20px;
  @media (max-width: 415px) {
    grid-template-columns: 100%;
    //font-size: 2px;
  }
`;

export const InputForm = styled.input`
  width: 100% !important;
  height: 50px !important;
  display: inline !important;
  border-radius: 7px !important;
`;

export const LineForm = styled.div`
  width: 100%;
`;

export const LabelInput = styled.label`
  display: block;
`;
