import React from "react";
import { Avatar } from "antd";
import {UserOutlined , ArrowRightOutlined , PaperClipOutlined} from '@ant-design/icons'
import * as S from './style'
import TransText , {ISRTL} from "../transtext/transtext";
import { Link } from "react-router-dom";
import { signOut } from "../fetch/fetch";
import icomtime from './Picker_time.png'
const UserAvatar = () => {
    const Logout = () => {
        signOut()
    }
    return(
<ul className="navbar-nav">
                
        <li  key={1} className={"menu-item menu-item-has-children "}>
            <Link  style={{margin:'0px' , padding:'0px'}}>
            <Avatar size={40} icon={<UserOutlined />} />
           
              {/* <TransText id="Home" default_message="test" /> */}
            </Link>
            {/* here start lang  */}
            <S.ULItem lang={ISRTL} className="sub-menu">
                <S.Flag>
                {/* <Avatar style={{ margin:'0px 5px 0px 2px' }} size={25} icon={<UserOutlined />} /> */}
                <UserOutlined style={{paddingTop:'7px'}} />
              <li
                key={2}
                className={"menu-item menu-item-has-children"}
              >
                
                <Link style={{display:'inline'}} to={'/user_profile'} style={{display:'block',width:'100%' , padding:'2px'}}>
               
                  <TransText id="My Profile" default_message="My Profile" /></Link>
              </li>
              
              </S.Flag>

              <S.Flag >
              {/* <img style={{display:'inline', margin:'0px 2px 0px 2px' }} src={icomtime} /> */}
              <PaperClipOutlined  style={{paddingTop:'7px'}}/>
              <li
                key={2}
                className={"menu-item menu-item-has-children"}
              >
                
                <Link style={{display:'inline'}} style={{display:'block !important',width:'100%', padding:'2px'}} to={'/appointments'} >
               
                <TransText id="my appointments" default_message="my appointments" />
                
                </Link>
                
                </li>
                
              </S.Flag>

              <S.Flag >
              <ArrowRightOutlined  style={{paddingTop:'7px'}}/>
              <li
              onClick={Logout}
                key={4}
                className={"menu-item menu-item-has-children"}
              >
                
                <Link style={{display:'inline',width:'100%' , padding:'2px'}} ><TransText id="Logout" default_message="Logout" /></Link>
              </li>
             
              </S.Flag>
            </S.ULItem>
         </li>
      </ul>
                           
    )
}

export default UserAvatar