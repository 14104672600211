import React, { Fragment } from "react";
import Navhelper from "../../helper/NavHelper";
import { Link } from "react-router-dom";
import navigation from "../../data/navigation.json";
import TransText, { ISRTL } from "../transtext/transtext";
import Button_language from "../button_language/button_language";
import { signOut } from "../fetch/fetch";

export let islogin = window.localStorage.getItem("login");

class Mobilemenu extends Navhelper {
  render() {
    return (
      <Fragment>
        <div
          style={{ padding: "10px", textAlign: `${ISRTL ? "left" : ""}` }}
          className="sigma_logo-wrapper"
        >
          <Link to="/" className="sigma_logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo-white.png"}
              alt="logo"
            />
          </Link>
        </div>
        <ul className="navbar-nav">
          {/* Data */}
          {/* {navigation.length > 0
            ? navigation.map((item, i) => (
                <li
                  key={i}
                  className={`menu-item ${
                    item.child ? "menu-item-has-children" : ""
                  } `}
                  onClick={this.triggerChild}
                >
                  {item.child ? (
                    <Link onClick={(e) => e.preventDefault()} to="/">
                      {" "}
                      {item.linkText}{" "}
                    </Link>
                  ) : (
                    <Link to={item.link}> {item.linkText} </Link>
                  )}
                  {item.child ? (
                    <ul className="sub-menu" role="menu">
                      {item.submenu.map((sub_item, i) => (
                        <li
                          key={i}
                          className={`menu-item ${
                            sub_item.child ? "menu-item-has-children" : ""
                          } `}
                        >
                          {sub_item.child ? (
                            <Link onClick={(e) => e.preventDefault()} to="/">
                              {" "}
                              {sub_item.linkText}{" "}
                            </Link>
                          ) : (
                            <Link to={sub_item.link}>
                              {" "}
                              {sub_item.linkText}{" "}
                            </Link>
                          )}
                          {sub_item.submenu ? (
                            <ul className="sub-menu">
                              {sub_item.submenu.map((third_item, i) => (
                                <li className="menu-item" key={i}>
                                  <Link to={third_item.link}>
                                    {third_item.linkText}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))
            : null} */}

          {/* my nav */}

          <li
            key={1}
            className={
              ISRTL
                ? ".sigma_aside .navbar-nav li.menu-item-has-children-ar"
                : "menu-item menu-item-has-children"
            }
            onClick={this.triggerChild}
          >
            <Link to="/">
              <TransText id="Home" default_message="Home" />
            </Link>
            {/* <ul className="sub-menu" role="menu">
              <li key={2} className={"menu-item "}>
                <Link to={"/"}>
                  <TransText id="Home" default_message="Home" />
                </Link>
              </li>
            </ul> */}
          </li>
          <li
            key={1}
            className={
              ISRTL
                ? ".sigma_aside .navbar-nav li.menu-item-has-children-ar"
                : "menu-item menu-item-has-children"
            }
            onClick={this.triggerChild}
          >
            <Link to="/all-item">
              <TransText id="Item" default_message="Item" />
            </Link>
          </li>
          {islogin == "true" ? (
            <li
              key={1}
              className={
                ISRTL
                  ? ".sigma_aside .navbar-nav li.menu-item-has-children-ar"
                  : "menu-item menu-item-has-children"
              }
              onClick={this.triggerChild}
            >
              <Link>
                <TransText id="My Profile" default_message="My Profile" />
              </Link>
              <ul className="sub-menu" role="menu">
                <li key={3} className={"menu-item "}>
                  <Link to="/user_profile">
                    <TransText id="My Profile" default_message="My Profile" />
                  </Link>
                </li>

                <li key={3} className={"menu-item "}>
                  <Link to="/appointments">
                    <TransText
                      id="my appointments"
                      default_message="my appointments"
                    />
                  </Link>
                </li>
                <li key={2} className={"menu-item "}>
                  <Link
                    onClick={() => {
                      signOut();
                    }}
                  >
                    <TransText id="Logout" default_message="Logout" />
                  </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {/* my nav */}
          {/* Data */}
        </ul>
      </Fragment>
    );
  }
}

export default Mobilemenu;
