import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Headertwo";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import Profile_Content from "../sections/profile-content/profile_content";
import { Fetch_API } from "../fetch/fetch";

const User_profile = () => {
  const [data, setData] = useState(null);

  const fetch = async () => {
    const res = await Fetch_API("account");
    //console.log(res);
    setData(res.data);
  };

  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <Header isprofile />
      <Breadcrumbs dontshow />
      <Profile_Content data={data} />
      <Footer />
    </>
  );
};

export default User_profile;
