import React from "react";
import styled from "styled-components";

export const Flag = styled.div`
  width: 100%;
  overflow: hidden;
  height: 50px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: gray;
  }
`;

export const MAINDIV = styled.div`
  display: ${(props) => (props.ishere ? "none!important" : "")};
  /* display: none; */
  @media (max-width: 970px) {
    display: ${(props) => (props.ishere ? "flex!important" : "")};
  }
  @media (max-width: 415px) {
    display: ${(props) => (props.ishere ? "flex!important" : "")};
  }
`;
export const IMGFlag = styled.img`
  width: 30px;
  overflow: hidden;
  height: 24px;
  margin: 10px 10px;
`;

export const LIFLAG = styled.li`
  &:hover {
    cursor: pointer;
    background-color: gray;
  }
`;

export const ULItem = styled.ul`
  flex-direction: column;
  left: ${(props) => (props.lang ? "0" : "")};
  right: ${(props) => (props.lang ? "" : "0")};
`;
