import React , {useState , useEffect} from "react";
import TransText , {ISRTL} from "../transtext/transtext";
import { Link } from "react-router-dom";
import img from './United States-1.png'
// import '../../assets/css/style.css';
import * as S from './styled';
import { configuration } from "../../config/config";

const Button_language = ({ishere}) => {
    const [lang , setLang] = useState('ar')
  //console.log(ishere)
  const fetchS_ID = async () => {
    let headers = { Authorization: configuration.Authentication };
    const res = await fetch(
      `https://api.1daycloud.com/v3/appointment/get_sid`,
      { headers }
    );
    const data = await res.json();
    if (data) {
      window.localStorage.setItem("s_id", data.data);
    }
   // console.log(data);
  };

    const OnClick = (id) => {
        setLang(id)
        if(window && window.localStorage){
            window.localStorage.setItem('lang' , id)
                window.location.reload();
        }else{
            return;
        }
        //console.log(lang)
    }

    useEffect(() => {

        let langSession = window.localStorage.getItem('lang') || null
        if(langSession){
            
        }else{
            window.localStorage.setItem('lang' , 'en')
        }
//////
        let islogin = window.localStorage.getItem("s_id") || null;
        if(islogin){

        }else{
          fetchS_ID()
        }

        let login = window.localStorage.getItem('login')
        if(login){

        }else{
          window.localStorage.setItem('login', false)
        }
        
    },[])
    return(
        <S.MAINDIV  ishere={ishere}  className="d-flex align-items-center">
            <ul className="navbar-nav">
                
        <li  key={1} className={"menu-item menu-item-has-children "}>
                      <Link to="#" style={{margin:'0px' , padding:'0px'}}>
                      <li style={{ fontSize:'37px', color:''}} className='fas fa-globe color_global' />
                        {/* <TransText id="Home" default_message="test" /> */}
                      </Link>
                      {/* here start lang  */}
                      <S.ULItem lang={ISRTL} className="sub-menu">
                          <S.Flag onClick={() => OnClick('ar')}>
                        <li
                          key={2}
                          className={"menu-item menu-item-has-children"}
                        >
                            <S.IMGFlag style={{display:'inline'}} src="https://res.cloudinary.com/dqbgnn5hf/image/upload/v1590068772/sa.png" />
                          <Link style={{display:'inline'}} to={"/"}><TransText id="ar" default_message="ar" /></Link>
                        </li>
                        </S.Flag>
                        <S.Flag onClick={() => OnClick('en')}>
                        <li
                          key={2}
                          className={"menu-item menu-item-has-children"}
                        >
                            <S.IMGFlag style={{display:'inline'}} src={img} />
                          <Link style={{display:'inline'}} to={"/"}><TransText id="en" default_message="en" /></Link>
                        </li>
                        </S.Flag>
                      </S.ULItem>
                    </li>
                    </ul>
                    </S.MAINDIV>
    )
}

export default Button_language;