import React, { Component } from "react";
import { Link } from "react-router-dom";
import TransText, { ISRTL } from "../transtext/transtext";
import styled from "styled-components";

export const HeaderBanner = styled.div`
  background-color: transparent;
  width: 100%;
  display: ${(props) => (props.dontshow ? "none" : "flex")};
  justify-content: center;
  gap: 20px;
  padding-top: 70px;
  padding-bottom: 0px;

  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: space-around;
    padding-top: 150px;
  }
  @media (max-width: 766px) {
    //flex-direction: column;
    flex-direction: column;
    padding-top: 100px;
  }
  @media (max-width: 415px) {
    //flex-direction: column;
    display: ${(props) => (props.ishere ? "flex" : "none")};
    padding-top: 100px;
  }
`;

export const Hide_div = styled.div`
  @media (max-width: 415px) {
    display: flex;
    flex-direction: column;
    display: ${(props) => (props.ishere ? "flex" : "none")};
  }
`;
export const Wrapper = styled.div`
  width: 40%;
  align-self: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 50%;
  }
  @media (max-width: 950px) {
    width: 80%;
  }
`;
export const IMG_LOGO = styled.img`
  width: 95%;
  height: 95%;
  @media (max-width: 1300px) {
    /* width: 50%;
    height: 50%; */
    align-self: center;
  }
`;

export const Respons_Div = styled.div`
  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export const IMG_Wrapper = styled.div`
  @media (max-width: 1300px) {
    align-self: center;
  }
`;

class Breadcrumbs extends Component {
  componentDidMount() {
    console.log(this.props);
  }
  render() {
    return (
      <Respons_Div
        className={
          this.props.ishere
            ? "sigma_subheader_Home style-5 bg-gray"
            : "sigma_subheader style-5 bg-gray"
        }
      >
        <HeaderBanner dontshow={this.props.dontshow} ishere={this.props.ishere}>
          <Wrapper ishere={this.props.ishere}>
            <h1 style={{ color: "#BAB7D4" }}>
              <TransText id="Min_Title" default_message="1DAY CLOUD" />
            </h1>
            <br />
            <p
              style={{
                fontFamily: `${ISRTL ? "arabicFont" : ""}`,
                fontSize: "20px",
                color: "#BAB7D4",
              }}
            >
              <TransText
                id="Header_Descrption"
                default_message="Header_Descrption"
              />
            </p>
          </Wrapper>
          <IMG_Wrapper>
            <IMG_LOGO
              src={
                process.env.PUBLIC_URL +
                `/assets/img/${
                  ISRTL ? "app-mobile-ar.png" : "app-mobile_Big.png"
                }`
              }
              alt="LOGO"
            />
          </IMG_Wrapper>
        </HeaderBanner>
        {/* here start baner */}
        <div className="container">
          {/* <div className="sigma_subheader-inner">
            {/* <h1>{this.props.breadcrumb.pagename}</h1> */}
          {/* <h1>
              <TransText id="Min_Title" default_message="1DAY CLOUD" />
            </h1>
           
          </div>  */}
          {/* <ol
            style={{
              direction: `${ISRTL ? "ltr" : ""}`,
            }}
            className="breadcrumb"
          >
            <li className="breadcrumb-item">
              <Link to="/" className="btn-link">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {this.props.breadcrumb.pagename}
            </li>
          </ol> */}
        </div>
        {/* here end baner */}

        {/*
        <img
          src={process.env.PUBLIC_URL + "/assets/img/subheader-bl.png"}
          className="bl"
          alt="subheader"
        />
        <img
          src={process.env.PUBLIC_URL + "/assets/img/subheader-tr.png"}
          className="tr"
          alt="subheader"
        /> */}
      </Respons_Div>
    );
  }
}

export default Breadcrumbs;
