import React , {useState} from "react";
import * as S from './style'
import { Formik } from "formik";
import * as Yup from 'yup';
import TransText,{ISRTL} from "../../transtext/transtext";
import { Alert ,DatePicker } from "antd";
import { signOut, Update_Account } from "../../fetch/fetch";


const Profile_Content = ({data}) => {

    const [gender , setGender] = useState(null)
    const [birth , setbirth] = useState(null)
    //console.log(data)

const onGender = async (values) => {
    setGender(values.target.value)
}
const onBirth = (e) => {
 const newbirth = e._d.toISOString().split("T")[0];
 setbirth(newbirth)
 //console.log(newbirth)
 
}

const onSend = async ({errors},values) => {
        //console.log(errors)
    let data = values
    if(gender){
        data.gender = gender
    }
    if(birth){
        data.birthday = birth
    }
    //console.log(data)
   const res = await Update_Account(data)
   //console.log(res , 'respons')
}
    return(
        <S.Wrapper>
            <S.LeftSide>
                <h5 style={{ cursor:'pointer' ,borderRight:`${ISRTL ? '5px solid' : ''}` ,borderLeft:`${ISRTL ? '' : '5px solid '}`, padding:'0px 10px' }}><TransText id='My Profile' default_message="My Profile" /> </h5>
                <h5 onClick={signOut}  style={{ cursor:'pointer'}}><TransText id='Logout' default_message="Logout" /> </h5>

                </S.LeftSide>
        <S.Contenr_Wrapper>
           <Formik initialValues={{
               first_name:'',
               last_name:'',
               birthday:'',
               gender:'',
               mobile:'',
               email:'',
               location:'',
               photo:''

           }} validationSchema={
               Yup.object({
                first_name: Yup.string(),
                last_name: Yup.string(),
                birthday: Yup.string(),
                gender: Yup.string(),
                mobile: Yup.number('number not valid'),
                email: Yup.string().email(ISRTL ? 'البريد الالكتروني غير صالح' :'email not valid').required(ISRTL ? 'البريد الالكتروني مطلوب' :'email is required'),
               })}
               onSubmit={(values) => onSend(values)  }
               >


               {({values, errors ,touched , handleChange , handleSubmit}) => (
                   
                   <S.USER_Form onSubmit={handleSubmit}>
                       <S.LineForm>
                       <S.LabelInput htmlFor='first_name' ><TransText id='Name' default_message='Name' /></S.LabelInput>
                        <S.InputForm id="first_name"  placeholder={data && data.first_name ? `${data.first_name}` : ''}  type='text' value={values.first_name} onChange={handleChange} />
                        </S.LineForm>

                        <S.LineForm>
                       <S.LabelInput htmlFor='last_name' ><TransText id='Last name' default_message='Last name' /></S.LabelInput>
                        <S.InputForm id="last_name" placeholder={data && data.last_name ? `${data.last_name}` : ''}  type='text'  value={values.last_name} onChange={handleChange}/>
                        </S.LineForm>

                        <S.LineForm>
                       <S.LabelInput htmlFor='email' ><TransText id='Email' default_message='Email' /></S.LabelInput>
                        <S.InputForm id="email"  type='text' placeholder={data && data.email ? `${data.email}` : ''} value={values.email} onChange={handleChange}/>
                        { errors.email && touched.email ?  <Alert  message={errors.email} type="error" showIcon /> : null}
                        </S.LineForm>

                        <S.LineForm>
                       <S.LabelInput htmlFor='mobile' ><TransText id='Mobile' default_message='Mobile' /></S.LabelInput>
                        <S.InputForm id="mobile" placeholder={data && data.mobile ? `${data.mobile}` : ''}  type='text'  value={values.mobile} onChange={handleChange}/>
                        { errors.mobile && touched.mobile ?  <Alert message={ISRTL ? "رقم غير صالح": 'number not valid'} type="error" showIcon /> : null}
                        </S.LineForm>

                        <S.LineForm style={{gap:'10px'}} className="d-flex align-items-center">
                      
                        <input style={{display:'inline'}} type='radio' id='Male' value={'Male'}  name="gender" onChange={(e) => onGender(e)} />
                        <S.LabelInput style={{display:'inline'}} htmlFor='Male' ><TransText id='Male' default_message='Male' /></S.LabelInput>  
                        
                        
                        <input style={{display:'inline'}} type='radio' id='Female' value={'Female'}  name="gender" onChange={(e) => onGender(e)} />
                        <S.LabelInput style={{display:'inline'}} htmlFor='Female' ><TransText id='Female' default_message='Female' /></S.LabelInput>
                        </S.LineForm >

                        <S.LineForm  >
                        <S.LabelInput style={{display:'inline'}} htmlFor='Birthday' ><TransText id='Birthday' default_message='Birthday' /></S.LabelInput>
                            <DatePicker placeholder={'yyyy-mm-dd'}  style={{width:'100%', cursor:'pointer'}} id="Birthday" onChange={onBirth} />
                        </S.LineForm >

                        <S.LineForm >
                            <button type='submit' ><TransText id='Submit' default_message='Submit' /></button>
                        </S.LineForm >
                        
                       </S.USER_Form>
               )}
               </Formik>
            </S.Contenr_Wrapper>
            </S.Wrapper>
    )
}

export default Profile_Content;