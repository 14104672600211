import React, { useEffect, useState } from "react";
import * as S from "./style";
import ar from "../Translation/ar.json";
import en from "../Translation/en.json";
import { Fetch_API } from "../fetch/fetch";
import { configuration } from "../../config/config";
// export const ISRTL = () => {
//   let lang = window.localStorage.getItem("lang");
//   let dir;
//   if (lang && lang === "ar") {
//     return (dir = true);
//   } else {
//     return (dir = false);
//   }
//   return dir;
// };
let lang = window.localStorage.getItem("lang");

export const ISRTL = lang === "ar" ? true : false;

const TransText = ({ id, default_message }) => {
  const [language, setLanguage] = useState();
  const [trans, setTrans] = useState([]);
  const Arabic = ar;
  const english = en;
  // const texttrans = () => {
  //   return trans[id] || default_message;
  // };
  useEffect(() => {
    let lang = window.localStorage.getItem("lang") || null;
    if (lang && lang === "ar") {
      setTrans(ar);
    } else if (lang && lang === "en") {
      setTrans(en);
    }

    /////
  }, []);

  return <>{trans[id] ? trans[id] : default_message}</>;
};

export default TransText;
